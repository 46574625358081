import React from 'react';
//import { Link } from 'gatsby';

import Layout from '../../components/Layout';
import Sidebar from '../../components/Sidebar';
import PostEntryMeta from '../../components/PostEntryMeta';
import PostTitle from '../../components/PostTitle';
import SEO from '../../components/SEO';
import GoogleAds from '../../components/GoogleAds';


class Post extends React.Component {

  componentDidMount() {
    // Fix legacy Graphicriver and Themeforest links
    var gr = document.querySelectorAll('a[href*="graphicriver"]')
    var tf = document.querySelectorAll('a[href*="themeforest"]')
    if (gr) {
      gr.forEach(function(element) {
        var old_url = element.href
        var clean_url = old_url.substring(0, old_url.indexOf('?'));
        var new_url = 'https://1.envato.market/c/257936/475676/4415?u='+encodeURIComponent(clean_url);
        element.setAttribute('href', new_url);
      })
    }
    if (tf) {
      tf.forEach(function(element) {
        var old_url = element.href
        var clean_url = old_url.substring(0, old_url.indexOf('?'));
        var new_url = 'https://1.envato.market/c/257936/475676/4415?u='+encodeURIComponent(clean_url);
        element.setAttribute('href', new_url);
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const post = this.props.pageContext
    return(
      <Layout>
        <SEO title={`${post.title}`} />

        <section className="layout-sidebar single single-post">
        <div className="row">
          
          <div id="primary" className="content-area mt-5">
            <article id={`post-${post.postId}`} className={`post post-${post.postId} type-post hentry`}>
              <header className="entry-header top-spacer bottom-spacer">
                <PostTitle
                  location="single"
                  post={post}
                  titleClass="entry-title h2"
                />
                <PostEntryMeta post={post} location="single" />
              </header>

              {/* Single post top Ad  */}
              <div className="pub-block entry-header-pub-block">
                <GoogleAds slot="2070396633" />
              </div>

              <div
                className="entry-content content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />

              {/* Single post bottom Ad  */}
              <div className="pub-block entry-pub-block">
                <GoogleAds slot="9015872482" />
              </div>

              <footer className="entry-footer flex justify-between">
                {/* TODO: add taxonomies to a new component */}
                {/*
                <div className="entry-footer__taxonomy justify-end self-center items-center">
                  {post.categories.nodes.length || post.tags.nodes.length
                    ? renderTerms(post.categories.nodes, post.tags.nodes)
                    : null}
                </div>
                */}
              </footer>
            </article>
          </div>{/* End of #primary */}

          <Sidebar/>

        </div>{/* End of .row */}
        </section>

      </Layout>
    )
  }
}

export default Post;