import React from 'react';

const config = require('../../config');

class GoogleAds extends React.Component {

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
        <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client={config.AdsenseAdClient}
            data-ad-slot={this.props.slot}
            data-ad-format= 'auto'
            data-full-width-responsive="true"
        ></ins>
        );
    }
}

export default GoogleAds;