import React from 'react';
import StickyBox from 'react-sticky-box';

import GoogleAds from './GoogleAds';

const Sidebar = () => (
  <aside id="sidebar" className="sidebar-area widget-area">
  	<StickyBox offsetTop={120}>
  		
  		<div className="ml-5">

  		{/* Sidebar responsive Ad  */}
      <div className="pub-block sidebar-pub-block">
        <GoogleAds slot="2554405913" />
      </div>

      <div className="bg-light p-5 text-center">
  			<span className="h5 mb-1">Grab my 
  			<br/><span className="h2 font-weight-bold">ULTIMATE</span><br/> list of creative assets</span>
  			<p className="mt-3 small">The 5 online platforms for high quality mockups, templates, fonts, graphics I use everyday to speed up my workflow</p>
  			<a href="https://layerbag.ck.page/39c986ce19" className="btn btn-md btn-dark rounded-0" target="_blank" rel="noopener noreferrer">Yes Please!</a>
  		</div>
  		
  		</div>

  	</StickyBox> 
  </aside>
);

export default Sidebar;
